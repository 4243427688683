<template>
    <div class="compare" @mouseup="changeEvent" :style="{ minWidth: tableWidths.table + 100 + 'px' }">
        <div class="compare-menu">
            <div class="top">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ $t('chan-pin-dui-bi') }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div class="main" v-loading="loading">
            <div v-if="!loading">
                <div class="table-content">
                    <div class="table-box" :class="{ moveBox: !!moveId }" ref="tableBox" v-if="showList.length > 0">
                        <div
                            class="head"
                            :class="{ fixedTop: scrollTop > 102 }"
                            :style="{ left: 50 - scrollLeft + 'px' }"
                        >
                            <div class="proTr headTr">
                                <div
                                    class="first"
                                    :class="{ fixedLeft: scrollLeft > 50 }"
                                    :style="{
                                        minWidth: tableWidths.first + 'px'
                                    }"
                                >
                                    {{ $t('dang-qian-lei-bie') }}
                                    <br v-if="!isChinese" />
                                    {{ getName(contrastCategory) }}
                                </div>
                                <div
                                    v-if="scrollLeft > 50"
                                    :style="{
                                        minWidth: tableWidths.first + 'px'
                                    }"
                                ></div>
                                <div
                                    v-for="(item, index) in showList"
                                    :key="item.id"
                                    class="col"
                                    :class="{ moveContrast: moveId == item.id, moveLeft: nowIndex === index }"
                                    :style="{ width: tableWidths[item.id] + 'px' }"
                                >
                                    <div
                                        class="edit"
                                        draggable="true"
                                        @dragstart="gdragstart($event, item.id, item)"
                                        @drag="gdrag($event, item.id)"
                                        @dragend="gdragend($event, item.id)"
                                    >
                                        <el-button type="text" class="move">
                                            <span class="iconfont">&#xe618;</span>
                                        </el-button>
                                        <el-button type="text" @click="delContrast(item.id)">
                                            <span class="iconfont">&#xe619;</span></el-button
                                        >

                                        <div class="drag-box">
                                            <product-row :info="item" :hasDel="false"></product-row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proTr" v-show="scrollTop > 102">
                                <div
                                    class="first"
                                    style="width: 250px; min-width: 250px"
                                    :class="{ fixedLeft: scrollLeft > 50 }"
                                    :style="{
                                        minWidth: tableWidths.first + 'px',
                                        minHeight: tableWidths.productMini + 'px'
                                    }"
                                >
                                    {{ $t('chan-pin') }}
                                </div>
                                <div
                                    v-if="scrollLeft > 50"
                                    :style="{
                                        width: tableWidths.first + 'px'
                                    }"
                                ></div>
                                <div
                                    v-for="(item, index) in showList"
                                    :key="item.id"
                                    class="col"
                                    :class="{ moveContrast: moveId == item.id, moveLeft: nowIndex === index }"
                                    :style="{ width: tableWidths[item.id] + 'px' }"
                                    v-resize="resizeInfo"
                                    data-productid="productMini"
                                >
                                    <product-row :info="item" :hasDel="false"></product-row>
                                </div>
                            </div>
                        </div>

                        <table
                            v-resize="resizeInfo"
                            data-productid="table"
                            class="table"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            id="compare"
                        >
                            <!-- <tr class="head">
                            <th colspan="2" class="first" style="width:250px">
                                当前类别：{{ getName(contrastCategory) }}
                            </th>
                            <th v-for="item in showList" :key="item.id">
                                <div class="edit">
                                    <el-button type="text" class="move">
                                        <span class="iconfont">&#xe618;</span>
                                    </el-button>
                                    <el-button type="text" @click="delContrast(item.id)">
                                        <span class="iconfont">&#xe619;</span></el-button
                                    >
                                </div>
                                <div
                                    class="hr"
                                    @mouseenter="resize"
                                    @mouseleave="delResize"
                                    @mousedown="startResize"
                                ></div>
                            </th>
                        </tr> -->

                            <tr>
                                <td
                                    colspan="2"
                                    data-productid="first"
                                    class="first"
                                    v-resize="resizeInfo"
                                    style="width: 250px; min-width: 250px; max-width: 250px"
                                >
                                    {{ $t('chan-pin') }}
                                </td>
                                <td
                                    v-for="(item, index) in showList"
                                    v-resize="resizeInfo"
                                    :key="item.id"
                                    :class="{ moveContrast: moveId == item.id, moveLeft: nowIndex === index }"
                                    :data-productid="item.id"
                                    class="poductWidth"
                                    :width="Math.floor(100 / (showList.length + 1)) + '%'"
                                >
                                    <product :info="item"></product>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('chan-pin-pin-pai') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    class="poductWidth"
                                    :key="productInfo.id"
                                >
                                    {{ getName(productInfo, ['brand', 'enBrand']) }}
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('ying-yong-ling-yu') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    class="poductWidth"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    :key="productInfo.id"
                                >
                                    {{ getApplicationField(productInfo) }}
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('chan-pin-biao-qian') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    class="poductWidth"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    :key="productInfo.id"
                                >
                                    <div class="tags">
                                        <el-tag
                                            v-for="(tag, tagIndex) in gstTags(productInfo)"
                                            :key="tagIndex"
                                            effect="plain"
                                            size="mini"
                                        >
                                            {{ getName(tag) }}
                                        </el-tag>
                                        <span v-if="gstTags(productInfo).length === 0">-</span>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('ping-jun-jiao-huo-zhou-qi') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    class="poductWidth"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    :key="productInfo.id"
                                >
                                    {{ productInfo.averageLeadTime }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('imt-zhan-ting-xian-xia-ti-yan-1') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    class="poductWidth"
                                    :key="productInfo.id"
                                >
                                    {{ productInfo.offlineExperience ? $t('ke-yi') : '-' }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('chan-di') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    class="poductWidth"
                                    :key="productInfo.id"
                                >
                                    {{ getOrigin(productInfo) }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="first">
                                    {{ $t('jian-jie') }}
                                </td>
                                <td
                                    v-for="(productInfo, index) in showList"
                                    :class="{ moveContrast: moveId == productInfo.id, moveLeft: nowIndex === index }"
                                    class="poductWidth"
                                    :key="productInfo.id"
                                >
                                    {{ getName(productInfo, ['introduction', 'enIntroduction']) }}
                                </td>
                            </tr>

                            <tr
                                v-for="(p, pIndex) in tableParameters"
                                :key="pIndex"
                                @mouseenter="choose($event, p.children ? p.children + 1 : 1)"
                                @mouseleave="removeChoose"
                            >
                                <td
                                    :rowspan="p.children ? p.children + 1 : 1"
                                    :colspan="!p.children && p.isPre ? 2 : 1"
                                    class="first"
                                    :class="[!p.children && !p.isPre ? 'second' : 'first']"
                                    :data-type="p.children ? 'first' : ''"
                                    :style="{ left: !p.children && !p.isPre ? firstWidth : 0 }"
                                >
                                    {{ getName(p) }}
                                </td>
                                <template v-if="!p.children">
                                    <td
                                        v-for="(item, index) in showList"
                                        :class="{ moveContrast: moveId == item.id, moveLeft: nowIndex === index }"
                                        class="poductWidth"
                                        :key="item.id"
                                    >
                                        {{ getKey(item.id, p.chName) }}
                                    </td>
                                </template>
                            </tr>
                        </table>
                    </div>

                    <empty-page v-if="showList.length === 0">
                        <span slot="text">{{ $t('shang-pin-dui-bi-lie-biao-wei-kong') }}</span>
                    </empty-page>
                </div>
            </div>

            <div
                class="table-left"
                v-if="scrollLeft > 50 && showList.length > 0"
                :style="{ transform: `translateY(${0 - scrollTop}px)`, top: tableWidths.tableTop + 'px' }"
            >
                <div v-for="(item, index) in leftList" :key="index" v-html="item" class="_tr"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import product from '../components/compared/product';
import contrast from '../mixins/contrast';
import productRow from '../components/product/RowMini.vue';
const observerMap = new Map();
export default {
    name: 'compare',
    data() {
        return {
            parameters: {},
            tableWidths: {},
            isResize: false,
            isMove: false,
            chooseInfo: {
                x: 0
            },
            scrollTop: 0,
            firstWidth: 0,
            leftList: [],
            leftFixed: false,
            scrollLeft: 0,
            loading: false,
            moveId: 0,
            moveMouseNode: null,
            nowIndex: -1,
            moveIndex: -1,
            showList: []
        };
    },
    mixins: [contrast],
    directives: {
        // 使用局部注册指令的方式
        resize: {
            // 指令的名称
            // bind(el, binding) {
            //     // el为绑定的元素，binding为绑定给指令的对象
            //     let width = '',
            //         height = '';
            //     function isReize() {
            //         const style = document.defaultView.getComputedStyle(el);
            //         if (width !== style.width || height !== style.height) {
            //             binding.value(style.width, el); // 关键
            //         }
            //         width = style.width;
            //         height = style.height;
            //     }
            //     el.__vueSetInterval__ = setInterval(isReize, 300);
            // },
            // unbind(el) {
            //     clearInterval(el.__vueSetInterval__);
            // }

            bind(el, binding) {
                const config = {
                    attributes: true,
                    childList: true,
                    characterData: true,
                    subtree: true
                };
                let width = '',
                    height = '';
                var _observer = new ResizeObserver(mutationsList => {
                    if (el.offsetWidth !== width) {
                        width = el.offsetWidth;
                        binding.value(el.offsetWidth, el);
                    }
                });
                if (_observer) {
                    _observer.observe(el, config);
                }

                if (_observer) {
                    observerMap.set(el, _observer);
                }
            },
            unbind(el) {
                observerMap.get(el).disconnect();
            }
        }
    },
    computed: {
        ...mapState(['contrastList', 'contrastCategory']),
        tableParameters() {
            const parameters = { ...this.parameters };
            const _map = new Map();
            for (let productId in parameters) {
                let _parameters = parameters[productId];
                _parameters.forEach(_parameter => {
                    if (!_map.has(_parameter.chName)) {
                        _map.set(_parameter.chName, {
                            chName: _parameter.chName,
                            enName: _parameter.enName,
                            sort: _parameter.value ? 1 : 0
                        });
                    } else if (_parameter.value) {
                        let before = { ..._map.get(_parameter.chName) };
                        _map.set(_parameter.chName, {
                            ...before,
                            sort: before.sort + 1
                        });
                    }

                    if (_parameter.children) {
                        _parameter.children.forEach(_child => {
                            if (!_map.has(_child.chName)) {
                                _map.set(_child.chName, {
                                    chName: _child.chName,
                                    enName: _child.enName,
                                    pre: _parameter.chName,
                                    sort: _child.value ? 1 : 0
                                });
                            } else if (_child.value) {
                                let before = { ..._map.get(_child.chName) };
                                _map.set(_child.chName, {
                                    ...before,
                                    sort: before.sort + 1
                                });
                            }
                        });
                    }
                });
            }

            let backList = [..._map.values()].sort((a, b) => {
                return b.sort - a.sort;
            });

            let list = backList.filter(item => {
                return !item.pre;
            });

            list = list.map(item => {
                let _num = backList
                    .filter(_c => {
                        return _c.pre === item.chName;
                    })
                    .reduce((total, curr) => {
                        return total + curr.sort;
                    }, 0);
                return [
                    {
                        ...item,
                        children: backList.filter(_c => {
                            return _c.pre === item.chName;
                        }).length,
                        isPre: true,
                        sort: _num || item.sort
                    },
                    backList.filter(_c => {
                        return _c.pre === item.chName;
                    })
                ];
            });

            list = list.sort((a, b) => {
                return b[0].sort - a[0].sort;
            });

            list = list.flat(Infinity);

            return list;
        },
        productParameterMap() {
            const _map = new Map();
            const parameters = { ...this.parameters };
            for (let productId in parameters) {
                let _parameters = parameters[productId];
                let _parametersMap = new Map();
                _parameters.forEach(_parameter => {
                    if (_parameter.value) {
                        _parametersMap.set(_parameter.chName, _parameter.value);
                    }

                    if (_parameter.children) {
                        _parameter.children.forEach(_child => {
                            if (_child.value) {
                                _parametersMap.set(_child.chName, _child.value);
                            }
                        });
                    }
                });
                _map.set(productId, _parametersMap);
            }

            return _map;
        }
    },
    watch: {
        contrastList() {
            this.showList = [...this.contrastList];
            if (this.contrastList.length > 0) {
                this.getParameters();
            }
        }
    },
    mounted() {
        this.showList = [...this.contrastList];
        if (this.contrastList.length > 0) {
            this.getParameters();
        }

        window.addEventListener('scroll', this.scrollEvent);
    },
    beforeRouteLeave(from, to, next) {
        this.$root.$el.style.minWidth = '1200px';
        next();
    },
    methods: {
        getParameters() {
            this.loading = true;
            this.$nextTick(() => {
                const width = {};
                width.first = 250;
                width.table = 1200;
                width.tableLeft = 0;
                width.tableTop = 0;
                width.productMini = 0;
                [...this.contrastList].forEach(item => {
                    width[item.id] = 0;
                });
                this.tableWidths = width;
                this.$http
                    .post('/productParameter/productsParameter', {
                        ids: this.contrastList
                            .map(item => {
                                return item.id;
                            })
                            .join(',')
                    })
                    .then(res => {
                        for (let key in res) {
                            res[key] = this.filter(res[key]);
                        }
                        this.parameters = res;
                        this.loading = false;

                        this.$nextTick(() => {
                            this.getTableInfo(document.getElementById('compare'));
                        });
                    });
            });
        },
        filter(list) {
            return list.map(item => {
                if (item.children.length > 0) {
                    return {
                        ...item,
                        chName: item.chName.replace(/ /g, ''),
                        children: this.filter(item.children)
                    };
                } else {
                    return {
                        ...item,
                        chName: item.chName.replace(/ /g, '')
                    };
                }
            });
        },
        checkKey(productId, name) {
            return this.productParameterMap.get(productId.toString()).has(name);
        },
        getKey(productId, name) {
            return this.productParameterMap.get(productId.toString()).has(name)
                ? this.productParameterMap.get(productId.toString()).get(name)
                : '-';
        },
        choose(e, rowspan) {
            // console.log(.nextSibling);
            if (this.moveId) {
                return;
            }
            const node = e.path[0];
            node.classList.add('choose');

            if (rowspan && rowspan !== 1) {
                let _node = e.path[0];
                for (var i = 1; i < rowspan; i++) {
                    _node = _node.nextSibling;
                    _node.classList.add('choose');
                }
            }
        },
        removeChoose() {
            document.getElementById('compare').children.forEach(item => {
                item.classList.remove('choose');
            });
        },
        resize() {
            this.$el.style.cursor = 'col-resize';
        },
        delResize() {
            if (!this.isResize) this.$el.style.cursor = 'default';
        },
        startResize(e) {
            this.isResize = true;
            this.chooseInfo = {
                x: e.clientX,
                node1: e.path[0].parentNode.previousSibling,
                node2: e.path[0].parentNode
            };
        },
        changeEvent() {
            this.isMove = false;
            this.isResize = false;
            this.$el.style.cursor = 'default';
        },
        moveEvent(e) {
            if (this.isResize) {
                const dx = e.clientX - this.chooseInfo.x;
                const w1 = dx + this.chooseInfo.node1.offsetWidth;
                const w2 = this.chooseInfo.node2.offsetWidth - dx;
                console.log(w1, w2);
                console.log(dx);
                console.log(this.$store.state.scale);
                if (w1 >= 380 && w2 >= 380) {
                    this.chooseInfo.node1.style.width = w1 + 'px';
                    this.chooseInfo.node2.style.width = w2 + 'px';

                    this.chooseInfo.x = e.clientX;
                }
            }
        },
        getProductCategoryName(productInfo) {
            if (productInfo.productCategory) {
                return this.getName(this.productInfo.productCategory);
            } else {
                return '';
            }
        },
        getApplicationField(productInfo) {
            let list = productInfo.applicationField ? [...productInfo.applicationField] : [];
            list = list.map(item => {
                return this.getName(item);
            });
            if (productInfo.customApplicationField) {
                const _list = this.getName(productInfo, ['customApplicationField', 'enCustomApplicationField']).split(
                    ','
                );

                list = [...list, ..._list];
            }
            return list.join('、');
        },
        gstTags(productInfo) {
            return productInfo.tag || [];
        },
        resizeInfo(width, e) {
            // console.log(width, e);
            if (e.dataset.productid) {
                this.tableWidths[e.dataset.productid] = e.dataset.productid === 'productMini' ? e.offsetHeight : width;
                this.$nextTick(() => {
                    if (e.dataset.productid === 'table') {
                        this.getTableInfo(e);
                    }
                });
            }
        },
        resizeFirst(width, e) {
            if (e.dataset.type === 'first' && this.firstWidth !== width) {
                this.firstWidth = width;
            }
        },
        getTableInfo(e) {
            this.tableWidths.tableLeft = e.offsetLeft + e.parentNode.offsetLeft;
            this.tableWidths.tableTop = 236;
            this.$root.$el.style.minWidth = e.offsetWidth + 100 + 'px';
            var list = [];
            const trs = [...e.children];
            trs.forEach((item, index) => {
                let info = '';

                if (item.children[0].attributes.colspan && item.children[0].attributes.colspan.value !== '1') {
                    info = `<div class='_td' style='width:${item.children[0].offsetWidth}px;height:${item.children[0].offsetHeight}px'>${item.children[0].innerHTML}</div>`;
                }

                if (item.children[0].attributes.rowspan && item.children[0].attributes.rowspan.value !== '1') {
                    info = `<div class='_td' style='width:${item.children[0].offsetWidth}px;height:${item.children[0].offsetHeight}px'>${item.children[0].innerHTML}</div>`;
                    info +=
                        trs
                            .slice(index + 1, Number(item.children[0].attributes.rowspan.value) + index)
                            .map((child, childIndex) => {
                                console.log();
                                return `${
                                    childIndex === 0
                                        ? '<div style="width:' + child.children[0].offsetWidth + 'px">'
                                        : ''
                                }<div class='_td' style='width:${child.children[0].offsetWidth}px;height:${
                                    child.children[0].offsetHeight
                                }px'>${child.children[0].innerHTML}</div>`;
                            })
                            .join('') + '</div>';
                }

                list.push(info);
            });

            this.leftList = list;
        },
        scrollEvent() {
            this.scrollTop = document.documentElement.scrollTop;
            this.scrollLeft = document.documentElement.scrollLeft;
        },
        chooseMove(id) {
            if (this.moveId === id) {
                this.moveId = 0;
            } else {
                this.moveId = id;
            }
        },
        gdragstart(e, id) {
            console.log('start');
            e.toElement.children[2].style.display = 'block';
            this.moveMouseNode = e.toElement.children[2];
            e.dataTransfer.setDragImage(new Image(), 0, 0);

            //  var img =
        },
        gdrag(e, id) {
            this.moveMouseNode.style.top = e.offsetY + 'px';
            this.moveMouseNode.style.left = e.offsetX + 'px';
            let { index, nowIndex } = this.getDragInfo(e, id);
            this.moveIndex = index;

            this.nowIndex = nowIndex;
        },
        gdragend(e, id) {
            this.moveMouseNode.style.display = 'none';
            let showList = [...this.showList];

            let { index, nowIndex } = this.getDragInfo(e, id);

            this.moveIndex = -1;
            this.nowIndex = -1;
            var _info = showList[index];
            showList.splice(index, 1);
            showList.splice(nowIndex, 0, _info);
            this.showList = showList;
        },
        getDragInfo(e, id) {
            const showList = [...this.showList];
            var index = showList.findIndex(item => {
                return item.id === id;
            });
            const widths = showList.map(item => {
                return this.tableWidths[item.id];
            });
            const leftList = widths.slice(0, index);
            const rightList = widths.slice(index, widths.length);

            const widthses = widths.map((item, _index) => {
                if (_index < index) {
                    return leftList.slice(_index, index - 1).reduce((pre, cur) => {
                        return pre + cur;
                    }, 0);
                } else {
                    return rightList.slice(0, _index - index + 1).reduce((pre, cur) => {
                        return pre + cur;
                    }, 0);
                }
            });

            const nowIndex = [...widthses].findIndex((item, _index) => {
                if (e.offsetX >= 0 && _index >= index) {
                    return e.offsetX < item;
                } else if (e.offsetX < 0 && _index < index) {
                    return Math.abs(e.offsetX) > item;
                } else {
                    return false;
                }
            });

            return {
                nowIndex,
                index
            };
        },
        getOrigin(info) {
            let name = [...info.origin1]
                .map(item => {
                    return this.getName(item);
                })
                .join(',');
            return name || this.$t(info.origin);
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    },
    components: {
        product,
        productRow
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.compare {
    // height: calc(100vh - 466px);
    // display: flex;
    // overflow: hidden;
    // flex-direction: column;
}
.compare-menu {
    flex-shrink: 0;
}
.top {
    padding: 30px 50px;
    margin: auto;
}
.main {
    background-color: #fff;
    padding: 30px 0;
    // min-height: max(calc(100vh - 463px - 71px), 100px);
    box-sizing: border-box;
    @include max-height(534px, $max: 1000px) // flex-grow: 1;
        // display: flex;
        // overflow: hidden;
        // flex-direction: column;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;
}
.table-content {
    // flex: 1;
    // display: flex;
}
.table {
    width: calc(100vw - 110px);
    min-width: 1100px;

    // border: 1px solid #f2f3f5;

    td {
        text-align: center;
        padding: 14px 0.3rem;
        color: #292c33;
        font-size: 12px;
        line-height: 22px;
        box-sizing: border-box;
        &.first {
            background: #0f264d;
            border-left: 1px solid #1f365c;
            color: #ffffff;
            font-weight: bold;
            cursor: context-menu;
        }
    }
    th {
        min-width: 300px;
        height: 44px;
        &.first {
            min-width: 250px;
            background: #ffa526;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 44px;
            max-width: 250px;
        }

        background: #f5f7fa;
        // position: relative;

        .hr {
            width: 1px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: #e6e9f0;
        }
    }

    tr {
        width: 100vw;
        &.choose {
            td {
                background: rgba(255, 165, 38, 0.12);
                &.first {
                    background: #0f264d;
                    color: #ffa526;
                }
            }
        }
        td {
            &.first {
                border-top: 1px solid #1f365c;
                border-left: 1px solid #1f365c;
            }
            border-top: 1px solid #f2f3f5;
            border-left: 1px solid #f2f3f5;
            box-sizing: border-box;

            // &:not(:first-child) {
            //     min-width: 300px;
            // }

            &.poductWidth {
                min-width: 300px;
            }
        }

        &:hover {
            td {
                background: rgba(255, 165, 38, 0.12);

                &.first {
                    background: #0f264d;
                    color: #ffa526;
                }
            }
        }
    }
}
.first {
    position: sticky;
    left: 0;

    &.fixedLeft {
        position: fixed;
        z-index: 20;
        left: 0;
    }
}
.fixedTop + .table {
    margin-top: 44px;
}
.head {
    z-index: 20;
    background-color: #fff;
    height: 44px;
    &.fixedTop {
        position: fixed;
        top: 90px;
        z-index: 30;
    }
    .proTr {
        display: flex;
        flex-wrap: nowrap;

        .first {
            min-width: 250px;
            text-align: center;
            background: #0f264d;
            color: #ffffff;
            font-weight: bold;
            cursor: context-menu;
            font-size: 12px;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #1f365c;
            flex-shrink: 0;

            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        }
        .col {
            min-width: 300px;
            padding: 14px 0.3rem;
            box-sizing: border-box;
            border-left: 1px solid #f2f3f5;
            background-color: #fff;

            flex-shrink: 0;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        }

        &.headTr {
            height: 44px;
            .first {
                min-width: 250px;
                background: #ffa526;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                line-height: 44px;
                max-width: 250px;
                text-align: center;
                border-left: 1px solid #ffa526;
                box-shadow: none;
            }
            .col {
                padding: 0 20px;
                background-color: #f5f7fa;
                box-shadow: none;
            }
        }
    }
}

.table-box {
    // overflow-x: auto;
    border: 1px solid #f2f3f5;

    &::-webkit-scrollbar {
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #333;
    }
    width: 100%;
}
.table-content {
    padding: 0 50px;
    margin: auto;
    position: relative;
    min-height: 500px;
    flex: 1;
}

.edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    position: relative;
    .iconfont {
        color: #878d99;
        font-size: 20px;
    }

    .el-button {
        padding: 0;
        &:hover {
            .iconfont {
                color: #ffa526;
            }
        }

        &.move {
            cursor: move;
        }
    }

    .drag-box {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        background-color: #fff;
        padding: 5px 10px;
        z-index: 20;
    }
}
.el-tag {
    background-color: transparent;
}
.el-tag + .el-tag {
    margin-left: 5px;
}

.headers {
    position: sticky;
    top: 0;
}

.product {
    text-align: left;
}
.info {
    padding: 0 0;
}

.isEn {
    .head .proTr.headTr .first {
        font-size: 12px;
        line-height: 16px;
        height: 44px;
    }
}
</style>
<style lang="scss">
.table-left {
    position: fixed;
    top: 576px;
    left: 0px;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 20;
    height: 100vh;
    ._tr {
        display: flex;
    }

    ._td {
        background: #0f264d;
        color: #fff;
        display: flex;
        font-size: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        border-bottom: 1px solid #1f365c;
        box-sizing: border-box;
    }
}
.moveBox {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #ffa52601;
    }
}
.moveContrast {
    position: relative;
    user-select: none;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #ffa52611;
    }
}

.moveLeft {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -2px;
        top: 0;
        width: 10px;
        background-color: #ffa526;
    }
}
</style>
