<template>
    <div class="info" @click="$router.push('/productDetail?id=' + info.id)">
        <el-avatar shape="square" :size="241" :src="img"></el-avatar>
        <div class="text1">{{ getName(info) }}</div>
        <div class="text2">{{ getName(info, ['model', 'enModel']) }}</div>
    </div>
</template>
<script>
export default {
    name: 'product',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        img() {
            if (this.info.img) {
                return this.info.img.split(',')[0];
            } else {
                return '';
            }
        }
    },
    data() {
        return {};
    }
};
</script>
<style lang="scss" scoped>
.info {
    padding: 15px 0.4rem;
    cursor: pointer;
}
.text1 {
    font-size: 14px;
    font-weight: bold;
    color: #292c33;
    line-height: 24px;
    margin-top: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text2 {
    font-size: 12px;
    color: #878d99;
    line-height: 22px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
